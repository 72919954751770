<template>
    <div id="loader" class="loader-wrapper">
        <div class="loader">
            <div class="loader-content">
                <div class="brand">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128"
                        enable-background="new 0 0 128 128" xml:space="preserve">
                        <g>
                            <rect width="128" height="128" />
                            <g>
                                <path fill="#FFFFFF" d="M50.2,0v18.3H16.7v91.4h33.5v18.3H0V0H50.2z M67,36.6H50.2V18.3H67V36.6z M67,109.7H50.2V91.4H67V109.7zM83.7,91.4H67V36.6h16.7V91.4z" />
                            </g>
                        </g>
                    </svg>
                    <h3 class="mt-4"> loading dio </h3>
                    <div>
                        <svg class="spinner" viewBox="0 0 50 50">
                            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.loader-wrapper {
    background: linear-gradient(-45deg, #333, #000);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}

.loader-content {
    animation: pulse 2s infinite;
    position: fixed;
    left: 38%;
    top: 28%;
    height: 24%;
    width: 24%;
    text-align: center
}

.brand {
    width: 100%;
    height: 100%;
}

.brand img,
.brand svg {
    position: relative;
    max-width: 95%;
    max-height: 95%
}

.spinner {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    margin: 24px 0 0 0;
    width: 24px;
    height: 24px
}

.spinner .path {
    stroke: #898888;
    stroke-linecap: round;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite
}

@keyframes pulse {

    0%,
    100% {
        opacity: .25
    }

    50% {
        opacity: 1
    }
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}</style>

