import {apiBaseUrl} from "@/config";
import axios, {AxiosRequestConfig, ResponseType} from "axios";
import Auth from "@/app-modules/authentication/Auth";
import {MessageBag} from "@/app-modules/message-bag/MessageBag";
import {Tasks} from "@/app-modules/state/Tasks";

export class Api {
    public static readonly BASE_URL = apiBaseUrl;
    public static readonly API = `${apiBaseUrl}/api`
    public static readonly IMG = `${apiBaseUrl}/img`

    static handleSuccess(taskId: any, response: any) {
        Tasks.changeTaskStatus(taskId,'done');
        return response.data.data ?? response.data;
    }

    static handleFailure(taskId: any, response: any) {
        const errorMessage = response.data.error ? response.data.error : ''
        MessageBag.addMessage({
            error: `Request ${taskId} failed with status code ${response.status} or response data is not as expected. ${errorMessage}`
        });
        console.error(`Request failed with status code ${response.status}`, response.data);
        Tasks.changeTaskStatus(taskId,'failed');
        return []
    }

    static handleException(taskId: any, error: any) {
        MessageBag.addMessage({
            error: `Request ${taskId} failed.`
        });
        console.error("Request error:", error);
        Tasks.changeTaskStatus(taskId,'error');
        return []
    }

    static handleResponse(response: { status: number; data: { data: any, error: string }; }, taskId: any = '') {
        try {
            if ((response.status !== 200 && response.status !== 201) || (!response.data)) return Api.handleFailure(taskId, response)
            return Api.handleSuccess(taskId, response)
        } catch (error) {
            return Api.handleException(taskId, error)
        }
    }

    static async makeRequest({
        endpoint = '',
        data = null,
        taskId = '',
        method = "get",
        responseType = "json" as ResponseType,
    }) {
        try {
            const config: AxiosRequestConfig = {
                method,
                url: `${Api.API}/${endpoint}`,
                headers: {
                    Authorization: `Bearer ${Auth.getToken()}`
                },
                responseType
            };

            if (method === 'post') config.data = data;

            const response = await axios(config);
            return this.handleResponse(response, taskId);
        } catch (error) {
            return Api.handleException(taskId, error)
        }
    }

    static async iwGet(endpoint: any, responseType: any = "json") {
        return await Api.makeRequest({
            endpoint: endpoint,
            method: 'get',
            responseType: responseType
        })
    }

    static async iwPost(endpoint: any, data: any) {
        return await Api.makeRequest({
            endpoint: endpoint,
            method: 'post',
            data: data
        })
    }

    static async listSizes() {
        return await Api.iwGet('listSizes');
    }

    static async getDuplicates() {
        return await Api.iwGet('duplicates');
    }

    static async listSimilarImages(ids: any, similarity = 0.88) {
        return await Api.iwPost('image/similar',{
            ids,
            similarity
        })
    }

    static async getModels() {
        return await Api.iwGet('models/list');
    }

    static async getFeedData(token: any, params: any = {}) {
        return await Api.iwGet('feed');
    }

    static async getFavorites() {
        return await Api.iwGet('listFavorites');
    }

    static async createFavorite(id: any) {
        return await Api.iwPost('favorite',{
            id: id,
        })
    }

    static async deleteFavorite(id: any, token: any) {
        return await Api.iwPost('deleteFavorite',{
            id: id,
        })
    }

    // scan
    static async scanForModels() {
        return await Api.iwGet('models/scan');
    }

    static async scanPromptPhrases() {
        return await Api.iwGet('scanPromptPhrases');
    }

    static async queryPromptPhrases(data: any) {
        return await Api.iwPost('queryPromptPhrases',data)
    }

    static async getPromptPhrases(token: any) {
        return await Api.iwGet('listPromptPhrases');
    }
}