import axios from "axios";
import {apiBaseUrl, loginRoute} from "@/config";
import {getAuth, onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import router from "@/router";
import {Clock} from "@/app-modules/clock/Clock";
import {Api} from "@/app-modules/api/Api";

export default class Auth {
    private token: any;

    constructor() {
    }

    static async register (email: string, password: string) {
        return await Api.iwPost('auth/register',{
            username: email,
            password: password
        })
    }

    static async verifyUser(): Promise<any> {
        return !!(Auth.getToken() && ((Auth.getElapsedSinceLastVerified() / 60) < 24));
    }

    static setLastVerified(): void {
        localStorage.setItem('lastVerified', Date.now().toString())
    }

    static getLastVerified(): string {
        return localStorage.getItem('lastVerified') ?? '0'
    }

    static async login(email = '', password = ''): Promise<any> {
        try {
            const { token, uid } = await Api.iwPost('auth/login',{
                username: email,
                password: password
            })
            Auth.saveToken(token)
            Auth.saveUid(uid)
            Auth.saveEmail(email)
            return true
        } catch (e) {
            console.error(e)
        }
    }

    static async logout() {
        // destroy data
        // Search.cleanLocalStorage()
        // destroy token
        Auth.clearUserInformation()
        // Sign-out successful.
        console.info('Sign out successful')
        router.push(loginRoute).then()
    }

    static async isAuthenticated(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const auth = getAuth();
            let isAuthenticated = false;

            // Add listener to only trigger once
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                isAuthenticated = !!user;
                unsubscribe(); // Unsubscribe to prevent future triggers
                resolve(isAuthenticated);
            });
        });
    }

    private static getElapsedSinceLastVerified() {
        return Clock.getElapsedMinutes(parseInt(Auth.getLastVerified()))
    }

    static clearUserInformation() {
        localStorage.removeItem('token')
        localStorage.removeItem('uid')
        localStorage.removeItem('email')
    }

    static saveToken(token: string) {
        localStorage.setItem('token', token)
        Auth.setLastVerified()
    }

    static saveUid(uid: string) {
        localStorage.setItem('uid', uid)
    }

    static saveEmail(email: string) {
        localStorage.setItem('email', email)
    }

    static getToken(): string {
        try {
            const token = localStorage.getItem('token') ?? ''
            if(!token) router.push('logout').then()
            return token;
        } catch (e) {
            console.error(e)
            return localStorage.getItem('token') || ''
        }
    }
}