export class Clock {
    
    // Returns the difference between start date and end date in seconds
    static elapsedTimeInSeconds(start: string, end: any = Date.now()): number {
        const startTime = new Date(start)
        const endTime = new Date(end)
        let timeDiff = endTime.valueOf() - startTime.valueOf()
        timeDiff /= 1000
    
        return Math.round(timeDiff)
    }
    
    // adds zeroes to the beginning of any number and returns a string
    static zeroFill(n: number, pad = 2): string {
        return n.toString().padStart(pad,'0')
    }
    /*
    * Converts a given number of milliseconds to hours, where number n is the number of milliseconds
    * Gets hours by dividing n by 1000 to convert it to seconds and then dividing it by 3600 (seconds in an hour).
    * rounded down to the nearest integer using Math.floor().
    */
    static msToHours(n: number) {
        return Math.floor((n / 1000) / 3600)
    }
    
    // Receives the elapsed time as milliseconds and returns a human-readable formatted string
    static getElapsedAsHumanTime(elapsed: number): string {
        const h = Clock.msToHours(elapsed)
        const i = Math.floor((elapsed / 1000) % 3600 / 60)
        const s = Math.floor((elapsed / 1000) % 60)
        const ms = elapsed > 999 ? Math.floor(elapsed % 1000) : elapsed
        return `${Clock.zeroFill(h)}:${Clock.zeroFill(i)}:${Clock.zeroFill(s)}:${Clock.zeroFill(ms,3)}`
    }
    
    // Receives any date and returns it as a UTC human-readable formatted string
    static getTimestamp(timestamp: Date): string {
        try {
            const date = `${timestamp.getUTCFullYear()}/${Clock.zeroFill(timestamp.getUTCMonth())}/${Clock.zeroFill(timestamp.getUTCDate())}`
            const time = `${Clock.zeroFill(timestamp.getUTCHours())}:${Clock.zeroFill(timestamp.getUTCMinutes())}:${Clock.zeroFill(timestamp.getUTCSeconds())}:${Clock.zeroFill(timestamp.getUTCMilliseconds(), 3)}`
            return `${date} ${time}`
        } catch (e) {
            console.error(e)
            return new Date().toString()
        }
    }
    
    // Sleep
    static async sleep(sleepTimer: any) {
        await new Promise(r => setTimeout(r, sleepTimer));
    }

    static getElapsedMinutes(start: any, end: any = Date.now()) {
        return Clock.elapsedTimeInSeconds(start, end) / 60
    }
}