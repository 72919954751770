import { createStore } from 'vuex'

export default createStore({
  state: {
    msg: '',
    user: {}
  },
  getters: {
    msg: state => {
      return state.msg
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {
  },
  modules: {
  }
})
