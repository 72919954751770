export class MessageBag {

    static addMessage(message: any): void {
        const messages = MessageBag.getMessages()
        messages.push(message);
        localStorage.setItem('messages', JSON.stringify(messages))
        window.dispatchEvent(new Event('newMessage'))
    }

    static getMessages(): any {
      const messages = localStorage.getItem('messages') || '[]'
      return JSON.parse(messages)
    }

    static clearMessages(): void {
        localStorage.setItem('messages', '[]')
    }

    static removeMessage(index: number): void {
        const messages = MessageBag.getMessages()
        messages.splice(index, 1)
        localStorage.setItem('messages', JSON.stringify(messages))
    }
}