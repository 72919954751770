export class Tasks {
    id: any
    name: any
    status: any
    ttl: any
    dtc: any
    dtm: any

    constructor() {
        this.id = `${Date.now().toString(36)}`
    }

    build({id = null, name = null, status = null, ttl = null}) {
        if(id) this.id = id
        let tasks = Tasks.getTasks()
        if(!tasks[this.id]) Tasks.addTask(this.id)
        this.name = name ? name : tasks[this.id]['name'] ? tasks[this.id]['name'] : ''
        this.status = status ? status : tasks[this.id]['status'] ? tasks[this.id]['status'] : 'start'
        this.ttl = ttl ? ttl : tasks[this.id]['ttl'] ? tasks[this.id]['ttl'] : 30000
        this.save()
    }

    static addTask(id: any): void {
        // console.log(`Adding new task ${id}`)
        const tasks = Tasks.getTasks()
        if(!tasks[id]) tasks[id] = {}
        tasks[id]['dtc'] = Date.now()
        tasks[id]['status'] = 'start'
        localStorage.setItem('tasks', JSON.stringify(tasks))
    }

    static getTasks(): any {
        const tasks = localStorage.getItem('tasks') || '{}'
        return JSON.parse(tasks)
    }

    static clearTasks(): void {
        localStorage.setItem('tasks', '{}')
    }

    static removeTask(id: any): void {
        const tasks = Tasks.getTasks()
        delete tasks[id]
        localStorage.setItem('tasks', JSON.stringify(tasks))
    }

    static changeTaskStatus(id: any, status: string) {
        const tasks = Tasks.getTasks()
        if(!tasks[id]) return false
        tasks[id]['status'] = status
        localStorage.setItem('tasks', JSON.stringify(tasks))
    }

    destroy() {
        Tasks.removeTask(this.id)
    }

    save() {
        const tasks = Tasks.getTasks()
        this.dtm = Date.now()
        tasks[this.id] = this
        localStorage.setItem('tasks', JSON.stringify(tasks))
    }
}