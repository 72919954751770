export const host = window.location.hostname;
// define global public constants
export const apiBaseUrl = `https://dio-api.orde.uk`
// define routes
// get login view route by name from router
export const loginRoute = {name: 'login'}
export const logoutRoute = {name: 'logout'}

const pinks = [
    '#FF85D6',
    '#FF5CA1'
];

const reds = [
    '#FF0000', // Red
    '#e74c3c'
]

const oranges = [
    '#FF7F00', // Orange
    '#FFA500',
    '#FF9F40',
    '#FFB300'
]

const yellows = [
    '#FFC61A',
    '#FFD633',
    '#FFFF00', // Yellow
    '#FFDD57'
]

const greens = [
    '#00FF00', // Green
    '#9ACD32',
    '#6C992B',
    '#76A830',
    '#8CC152',
    '#7ED321',
    '#48C774',
    '#00B386',
    '#17A589',
    '#117964',
    '#148F77',
    '#009170',
    '#00A180',
    '#00CC99',
    '#1ABC9C',
    '#34BDBD'
]

const blues = [
    '#536DFE',
    '#3366FF',
    '#2948FF',
    '#1A33FF',
    '#002BFF',
    '#0000FF' // Blue
]

const purples = [
    '#8B00FF', // Violet
    '#4B0082', // Indigo
    '#4D37CC',
    '#614CFF',
    '#7365CC',
    '#8C7AE6',
    '#A29BFE'
]

const grays = [
    '#9999CC',
    '#8C8CB3',
    '#B8B8F3',
    '#B3B3E6',
    '#D1D1FA'
]

export const rainbow_colors = [
    ...pinks,
    ...reds,
    ...oranges,
    ...yellows,
    ...greens,
    ...blues,
    ...purples,
    ...grays
]

export const positive_colors = [
    ...pinks,
    ...oranges,
    ...yellows,
    ...greens,
    ...blues,
    ...purples,
    ...grays
]

export const negative_colors = [
    ...reds
]