import router from "@/router";

export class Navigator {
    static getLastRequestedPage(): string {
        return localStorage.getItem('lastRequestedPage') || ''
    }

    static setLastRequestedPage(to: any): void {
        localStorage.setItem('lastRequestedPage', to)
    }

    static getCurrentPage(): any {
        return router.currentRoute
    }
}