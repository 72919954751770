import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import loginView from "@/views/LoginView.vue";
// import Auth from "@/app-modules/authentication/Auth";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {Navigator} from "@/app-modules/navigation/Navigator";
import {MessageBag} from "@/app-modules/message-bag/MessageBag";
import {loginRoute} from "@/config";
import Auth from "@/app-modules/authentication/Auth";

const routes: Array<RouteRecordRaw> = [
    // add homeView
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'home',
            requiresAuth: false
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
    },
    // add loginView
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'login',
            requiresAuth: false
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
    },
    // add loginView
    {
        path: '/register',
        name: 'register',
        meta: {
            title: 'register',
            requiresAuth: false
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/RegisterView.vue')
    },
    // add logoutView
    {
        path: '/logout',
        name: 'logout',
        meta: {
            title: 'logout',
            requiresAuth: false
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "logout" */ '../views/LogoutView.vue')
    },
    // add dashboardView
    // {
    //     path: '/dashboard',
    //     name: 'dashboard',
    //     meta: {
    //         title: 'dashboard',
    //         requiresAuth: true
    //     },
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue')
    // },
    // add duplicatesView
    {
        path: '/duplicates',
        name: 'duplicates',
        meta: {
            title: 'duplicates',
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "duplicates" */ '../views/DuplicatesView.vue')
    },
    {
        path: '/parameters/:id?',
        name: 'parameters',
        meta: {
            title: 'parameters',
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "duplicates" */ '../views/ParametersView.vue')
    },
    // add directoriesView
    {
        path: '/directories/:id?',
        name: 'directories',
        meta: {
            title: 'directories',
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "directories" */ '../views/DirectoriesView.vue')
    },

    // add modelsView
    {
        path: '/models',
        name: 'models',
        meta: {
            title: 'models',
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "models" */ '../views/ModelsView.vue')
    },
    // add favoritesView
    {
        path: '/favorites',
        name: 'favorites',
        meta: {
            title: 'favorites',
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "favorites" */ '../views/FavoritesView.vue')
    },
    // add collections
    {
        path: '/collections/:id?',
        name: 'collections',
        meta: {
            title: 'collections',
            requiresAuth: true

        },
        component: () => import(/* webpackChunkName: "collections" */ '../views/CollectionsView.vue')
    },
    // add searchView
    {
        path: '/search',
        name: 'search',
        meta: {
            title: 'search',
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "search" */ '../views/SearchView.vue')
    },
    // add settings view
    {
        path: '/settings',
        name: 'settings',
        meta: {
            title: 'settings',
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue')
    },
    // add importerView
    {
        path: '/import',
        name: 'import',
        meta: {
            title: 'import',
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "importer" */ '../views/ImportView.vue')
    },
    // add catch all route
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        meta: {
            title: 'notFound',
            requiresAuth: false
        },
        component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFoundView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const DEFAULT_TITLE = 'dio';

router.beforeEach(async (to, from, next) => {
    if (to.meta?.requiresAuth) {
        try {
            const user  = await Auth.verifyUser()
            if (user) {
                next()
            } else {
                Navigator.setLastRequestedPage(to.path)
                MessageBag.addMessage({
                    warning: '403 NOT AUTHORIZED - You must be logged in to access that page.',
                    ttl: 5000
                })
                next(false)
                await router.push(loginRoute)
            }
        } catch (e) {
            console.error(e)
            next(false)
        }
    } else {
        next()
    }
})

router.afterEach((to, from) => {
    try {
        if (!to.meta.title) return
        document.title = `${DEFAULT_TITLE} | ${to.meta.title}`
    } catch (error) {
        console.error(error)
    }
});
export default router
